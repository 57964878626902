import { Box, Button, FormControlLabel, FormGroup, FormHelperText, Stack, styled } from "@mui/material"

const SContainer = styled(Box)({
  width: "100%",
  height: "calc(100vh - 100px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const SFormGroup = styled(FormGroup)({
  width: "408px",
})

const SLogo = styled("img")({
  display: "block",
  width: "180px",
  marginBottom: "50px",
})

const SButton = styled(Button)({
  width: "164px",
  height: "48px",
})

const SStack = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "40px",
})

const SFormControlLabel = styled(FormControlLabel)({
  marginRight: 0,
})

const SAuthError = styled(FormHelperText)({
  marginLeft: "14px",
})

export { SContainer, SFormGroup, SLogo, SStack, SButton, SFormControlLabel, SAuthError }
